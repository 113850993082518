<template>
<FrForm class="mt-8 mx-auto w-full max-w-sm lg:w-96">

  <FrReveal>
    <FrAlert color="danger" v-if="error">
      {{ error }}
    </FrAlert>
  </FrReveal>

  <FrReveal appear>
    <FrAlert danger v-if="hasMSError">
      <p>There is not account linked to this Microsoft account</p>
    </FrAlert>
  </FrReveal>

  <FrFormItem unpadded class="mb-4">
    <FrLabel>{{ t('users.email') }}</FrLabel>
    <FrInput
      email
      unpadded
      plain
      @change="error = false"
      @key-enter="enterEmail"
      v-model="email">
    </FrInput>
  </FrFormItem>

  <FrReveal>
    <div v-show="authType === 'password'">
      <FrFormItem unpadded class="mb-4">
        <FrLabel>{{ t('users.password') }}</FrLabel>
        <FrInput
          password
          unpadded
          plain
          name="password"
          ref="refPass"
          @change="error = false"
          @key-enter.stop="enterPass"
          v-model="password">
        </FrInput>
      </FrFormItem>
    </div>
  </FrReveal>

  <a
    :href="kurl('/reset')" class="font-medium text-lightblue hover:text-lightblue-dark">
    {{ t('site.forgot_password') }}
  </a>

  <template v-if="authType === 'password'">
    <button
      @click="enterPass"
      class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lightblue hover:bg-lightblue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      {{ t('account.log_in') }}
    </button>
  </template>
  <template v-else>
    <button
      v-if="authType !== 'password'"
      class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lightblue hover:bg-lightblue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      type="button" @click="enterEmail">
      {{ t('audits.next') }}
    </button>
  </template>


  <div v-if="displayMS" class="mt-4">
    <a href="/login-microsoft">
      <img :src="mssignin" alt="Sign in with Microsoft"/>
    </a>
  </div>

</FrForm>
</template>
<script setup>
import { ref, inject, computed } from 'vue'

import { get } from 'lodash'
import mssignin from '../../../../../images/ms-signin.png'
import {
  FrAlert,
  FrFormItem,
  FrLabel,
  FrInput,
  FrReveal,
  FrForm,
} from '@frui'

const kurl = inject('kurl')
const t = inject('t')

const hasMSError = ref(false)
const email = ref('')
const password = ref('')
const authType = ref('')
const csrf = ref(document.querySelector('meta[name="csrf-token"]').content)
const error = ref(false)

const refPass = ref(null)

const displayMS = computed(() => {
  return true
})

const getAuthMethod = async () => {
  if (!email.value) {
    return
  }

  let uri = window.location.search.substring(1);
  let params = new URLSearchParams(uri);
  let extraParam = params.get("okta") ? '?okta=true' : '';

  try {
    const res = await axios.post('/api/auth/method' + extraParam, {
      email: email.value
    })

    authType.value = res.data.data

    if (authType.value !== 'password') {
      window.location.assign(authType.value)
    }
  } catch (e) {
    alert(t('errors.auth'))
  }
}

const enterEmail = async() => {
  await getAuthMethod()
  refPass.value.inputRef.focus()
}

const post = async() => {
  try {
    const rs = await axios.post('/api/web-login', {
      _token: csrf.value,
      email: email.value,
      password: password.value
    })

    const userId = get(rs, 'data.data.user.id')
    const url = get(rs, 'data.data.redirect')
    if (userId && url) {
      window.location.assign(url)
    } else {
      error.value = t('account.incorrect_username')
    }
  }
  catch (err) {
    if (err.response.status == 419) {
      error.value = 'CSRF token mismatch'
    } else {
      error.value = t('account.incorrect_username')
    }
  }

}

const enterPass = () => {
  post()
}

const init = () => {
  localStorage.clear()
  const params = new URLSearchParams(document.location.search);
  if (params.get('error') === 'ms') {
    hasMSError.value = true
  }
}


init()
</script>
