<template>
<div class="flex justify-center space-x-5 bg-white py-5">
  <a :href="kurl('/')" class="text-xl hover:text-gray-dark">
    {{ t('site.home') }}
  </a>
  <a :href="kurl('/app')" class="text-xl hover:text-gray-dark">
    {{ t('site.app') }}
  </a>
  <a :href="kurl('/contact')" class="text-xl hover:text-gray-dark">
    {{ t('site.contact') }}
  </a>
</div>
<div class="flex flex-wrap bg-white justify-center space-x-3">
  <button
    v-for="lang in langs"
    v-show="lang.icon_svg"
    href=""
    class="text-lightblue hover:text-lightblue-dark hover:underline"
    @click.prevent="setLang(lang)">
    {{ lang.name }}
  </button>
</div>
</template>
<script setup>
import { ref, inject } from 'vue'

const t = inject('t')
const kurl = inject('kurl')
const langs = ref([])

const getLang = async () => {
  const rs = await axios.get('/api/language')
  for (const val of rs.data.data) {
    if (val.icon_svg) {
      langs.value.push(val)
    }
  }
}

const setLang = (lang) => {
  i18n.global.locale.value = lang.code
  axios.get('/lang/' + lang.code)
}

const init = () => {
  getLang()
}

init()

</script>
