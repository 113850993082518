import '../../../../bootstrap';

import {createApp, h} from "vue";
import Login from "./Login.vue";

const login = createApp({
    components: {
        Login,
    },
    render: () => (
        h(Login)
    ),
});
login.use(i18n)
login.mount('#login')

