<template>
  <div>
    <section
      aria-label="Login section"
      class="w-screen flex inset-0 overflow-y-scroll overflow-x-hidden flex-wrap">

      <LoginBg></LoginBg>

      <section
        aria-label="Login form"
        class="order-0 lg:flex-initial h-screen py-12 px-4 sm:px-6 lg:px-20 xl:px-24 bg-white flex flex-col justify-content-center align-items-center place-content-center flex-1">

        <hgroup class="lg:mx-0 lg:text-left mx-auto text-center">
          <a :href="kurl('/')">
            <img :src="logo" alt="Logo" class="lg:block h-8 w-auto">
          </a>
          <h2 class="font-idealbold mt-6 text-3xl font-extrabold text-gray-900">
            {{ $t('site.login') }}
          </h2>
        </hgroup>

        <LoginForm></LoginForm>

      </section>

    </section>
    <LoginFooter></LoginFooter>
  </div>
</template>
<script setup>
import {useI18n} from 'vue-i18n';
import { computed, ref, provide } from 'vue'
import logo from '../../../../../images/freshability-logo-full.svg'

import LoginBg from './LoginBg.vue'
import LoginForm from './LoginForm.vue'
import LoginFooter from './LoginFooter.vue'

import "../../../../modernizr-build"

const { t } = useI18n({useScope: 'global'})

const kurl = (str) => {
  return window.kohana_url + str
}

provide('t', t)
provide('kurl', kurl)

</script>
