<template>
  <aside
    aria-label="Background"
    class="order-1 flex-1 h-screen hidden lg:block">
    <figure ref="background" class="h-full" role="group">
      <img :class="[
                  isHidden(0) ? 'hidden' : null
                ]"
           alt=""
           class="w-full h-full object-cover"
           src="https://images.unsplash.com/photo-1600917016506-556622b74303?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1502&q=80">
      <img :class="[
                  isHidden(1) ? 'hidden' : null
                ]"
           alt=""
           class="w-full h-full object-cover"
           src="https://images.unsplash.com/photo-1536330256861-f31e792c966d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80">
      <img :class="[
                  isHidden(2) ? 'hidden' : null
                ]"
           alt=""
           class="w-full h-full object-cover"
           src="https://images.unsplash.com/photo-1602470521006-aaea8b2fcc36?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80">
      <img :class="[
                  isHidden(3) ? 'hidden' : null
                ]"
           alt=""
           class="w-full h-full object-cover"
           src="https://images.unsplash.com/photo-1579370806239-bbaf91e1ee14?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80">
      <img :class="[
                  isHidden(4) ? 'hidden' : null
                ]"
           alt=""
           class="w-full h-full object-cover"
           src="https://images.unsplash.com/photo-1523198205441-99fac53d157f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80">
      <img :class="[
                  isHidden(5) ? 'hidden' : null
                ]"
           alt=""
           class="w-full h-full object-cover"
           src="https://images.unsplash.com/photo-1605690258824-d3ececd2741d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80">
    </figure>
  </aside>
</template>
<script setup>
const selected = Math.floor(Math.random() * 6)

const isHidden = (num) => {
  if (num !== selected) {
    return true
  }
}
</script>
